import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/index',
		name: 'index',
		component: () => import('@/views/index.vue'),
		meta: {
			title: '管理监控后台',
			keepAlive: true,
		},
		children: [{
				path: '/deviceManagement',
				name: 'deviceManagement',
				component: () => import('@/views/deviceManagement/deviceManagement.vue')
			},
			{
				path: '/consumption',
				name: 'consumption',
				component: () => import('@/views/deviceManagement/consumption.vue')
			},
			{
				path: '/appArrange',
				name: 'appArrange',
				component: () => import('@/views/deviceManagement/appArrange.vue')
			},
			{
				path: '/alarmLog',
				name: 'alarmLog',
				component: () => import('@/views/deviceManagement/alarmLog.vue')
			},
			{
				path: '/stagIng',
				name: 'stagIng',
				component: () => import('@/views/stagIng/stagIng.vue')
			},
			{
				path: '/customerList',
				name: 'customerList',
				component: () => import('@/views/customer/customerList.vue')
			},
			{
				path: '/auditList',
				name: 'auditList',
				component: () => import('@/views/customer/auditList.vue')
			},
			{
				path: '/industryClassification',
				name: 'industryClassification',
				component: () => import('@/views/customer/industryClassification.vue')
			},
			{
				path: '/fileManagement',
				name: 'fileManagement',
				component: () => import('@/views/fileManagement/fileManagement.vue')
			},
			{
				path: '/systemUsers',
				name: 'systemUsers',
				component: () => import('@/views/systemManagement/systemUsers.vue')
			},
			{
				path: '/permissionManagement',
				name: 'permissionManagement',
				component: () => import('@/views/systemManagement/permissionManagement.vue')
			},
			{
				path: '/receiveTransmission',
				name: 'receiveTransmission',
				component: () => import('@/views/dataTransmission/receiveTransmission.vue')
			},
			{
				path: '/sendTransmission',
				name: 'sendTransmission',
				component: () => import('@/views/dataTransmission/sendTransmission.vue')
			},
			{
				path: '/goDeviceDetalis/:id',
				name: 'goDeviceDetalis',
				component: () => import('@/views/deviceManagement/goDeviceDetalis.vue')
			},
			{
				path: '/custDetalis/:id',
				name: 'custDetalis',
				component: () => import('@/views/customer/custDetalis.vue')
			},
		]
		// children: [{
		//     path: '/goodsManage',
		//     name: 'goodsManage',
		//     component: () => import('@/views/gift/goodsManage.vue')
		// }, {
		//     path: '/sportsContract',
		//     name: 'sportsContract',
		//     component: () => import('@/views/gift/sportsContract.vue')
		// }, {
		//     path: '/commentManage',
		//     name: 'commentManage',
		//     component: () => import('@/views/gift/commentManage.vue')
		// }, {
		//     path: '/categoryManage',
		//     name: 'categoryManage',
		//     component: () => import('@/views/gift/categoryManage.vue')
		// }, {
		//     path: '/specManage',
		//     name: 'specManage',
		//     component: () => import('@/views/gift/specManage.vue')
		// }, {
		//     path: '/returnReasonManage',
		//     name: 'returnReasonManage',
		//     component: () => import('@/views/gift/returnReasonManage.vue')
		// }, {
		//     path: '/roleCheck',
		//     name: 'roleCheck',
		//     component: () => import('@/views/check/roleCheck.vue')
		// }, {
		//     path: '/activityCheck',
		//     name: 'activityCheck',
		//     component: () => import('@/views/check/activityCheck.vue')
		// }, {
		//     path: '/teamCheck',
		//     name: 'teamCheck',
		//     component: () => import('@/views/check/teamCheck.vue')
		// }, {
		//     path: '/withdrawCheck',
		//     name: 'withdrawCheck',
		//     component: () => import('@/views/check/withdrawCheck.vue')
		// }, {
		//     path: '/officialActivity',
		//     name: 'officialActivity',
		//     component: () => import('@/views/activity/officialActivity.vue')
		// }, {
		//     path: '/addActivity',
		//     name: 'addActivity',
		//     component: () => import('@/views/activity/addActivity.vue')
		// }, {
		//     path: '/viewActivity',
		//     name: 'viewActivity',
		//     component: () => import('@/views/activity/viewActivity.vue')
		// }, {
		//     path: '/brandActivity',
		//     name: 'brandActivity',
		//     component: () => import('@/views/activity/brandActivity.vue')
		// }, {
		//     path: '/activityPrize',
		//     name: 'activityPrize',
		//     component: () => import('@/views/activity/activityPrize.vue')
		// }, {
		//     path: '/activityStatementRule',
		//     name: 'activityStatementRule',
		//     component: () => import('@/views/activity/activityStatementRule.vue')
		// }, {
		//     path: '/activityStatementDuty',
		//     name: 'activityStatementDuty',
		//     component: () => import('@/views/activity/activityStatementDuty.vue')
		// }, {
		//     path: '/userManage',
		//     name: 'userManage',
		//     component: () => import('@/views/user/userManage.vue')
		// }, {
		//     path: '/goodsOrder',
		//     name: 'goodsOrder',
		//     component: () => import('@/views/order/goodsOrder.vue')
		// }, {
		//     path: '/officialActivityOrder',
		//     name: 'officialActivityOrder',
		//     component: () => import('@/views/order/officialActivityOrder.vue')
		// }, {
		//     path: '/brandActivityOrder',
		//     name: 'brandActivityOrder',
		//     component: () => import('@/views/order/brandActivityOrder.vue')
		// }, {
		//     path: '/sportsContractOrder',
		//     name: 'sportsContractOrder',
		//     component: () => import('@/views/order/sportsContractOrder.vue')
		// }, {
		//     path: '/refundManage',
		//     name: 'refundManage',
		//     component: () => import('@/views/order/refundManage.vue')
		// }, {
		//     path: '/refundAddressManage',
		//     name: 'refundAddressManage',
		//     component: () => import('@/views/order/refundAddressManage.vue')
		// }, {
		//     path: '/adManage',
		//     name: 'adManage',
		//     component: () => import('@/views/ad/adManage.vue')
		// }, {
		//     path: '/scaling',
		//     name: 'scaling',
		//     component: () => import('@/views/system/scaling.vue')
		// }, {
		//     path: '/roleIntroduce',
		//     name: 'roleIntroduce',
		//     component: () => import('@/views/system/roleIntroduce.vue')
		// }, {
		//     path: '/homePageModule',
		//     name: 'homePageModule',
		//     component: () => import('@/views/system/homePageModule.vue')
		// }, {
		//     path: '/heatingRecharge',
		//     name: 'heatingRecharge',
		//     component: () => import('@/views/system/heatingRecharge.vue')
		// }, {
		//     path: '/contactUs',
		//     name: 'contactUs',
		//     component: () => import('@/views/system/contactUs.vue')
		// }, {
		//     path: '/systemUser',
		//     name: 'systemUser',
		//     component: () => import('@/views/system/systemUser.vue')
		// }, {
		//     path: '/roleAuth',
		//     name: 'roleAuth',
		//     component: () => import('@/views/system/roleAuth.vue')
		// }, {
		//     path: '/hobby',
		//     name: 'hobby',
		//     component: () => import('@/views/system/hobby.vue')
		// }]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/',
		redirect: '/login',
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
