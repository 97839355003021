const title = function (title) {
    document.title = title;
}

// 手机号验证
const is_phone = function (phone) {
    let br = true;
    if (!(/^0?(13[0-9]|14[5-9]|15[012356789]|166|17[0-9]|18[0-9]|19[8-9])[0-9]{8}$/.test(phone))) {
        br = false
    }
    return br
}
// 邮箱验证
const is_email = function (email) {
    let br = true;
    if (!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email))) {
        br = false
    }
    return br
}
// 非空验证
const is_text = function (text) {
    let br = true;
    if (!(/\S/.test(text))) {
        br = false
    }
    return br
}
// 密码验证
const is_password = function (text) {
    let br = true;
    if (!(/^[a-zA-Z0-9]{6,12}$/.test(text))) {
        br = false
    }
    if (!(/^(?![^a-zA-Z]+$)(?!\D+$)/.test(text))) {
        br = false
    }
    return br
}

const isMobile = function () {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
}

// 身份证验证
const is_card = function (code) {
    //身份证号合法性验证
    //支持15位和18位身份证号
    //支持地址编码、出生日期、校验位验证
    var city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 "
    };
    var row = true;
    var msg = "验证成功";

    if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)) {
        row = false,
            msg = "被保人身份证号格式错误";
    } else if (!city[code.substr(0, 2)]) {
        row = false,
            msg = "被保人身份证号地址编码错误";
    } else {
        //18位身份证需要验证最后一位校验位
        if (code.length == 18) {
            code = code.split('');
            //∑(ai×Wi)(mod 11)
            //加权因子
            var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            //校验位
            var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
            var sum = 0;
            var ai = 0;
            var wi = 0;
            for (var i = 0; i < 17; i++) {
                ai = code[i];
                wi = factor[i];
                sum += ai * wi;
            }
            if (parity[sum % 11] != code[17].toUpperCase()) {
                row = false,
                    msg = "被保人身份证号校验位错误";
            }
        }
    }
    return row;
}

// 存储信息
const set_data = function (key, val) {
    localStorage.setItem(key, JSON.stringify(val));
}
// 读取信息
const get_data = function (key) {
    return JSON.parse(localStorage.getItem(key));
}

// 超出省略
const slh = function (str, length) {
    if (str.length > length + 3) {
        str = str.substring(0, length) + "..."
    }
    return str;
}

// 获取当前日期
function getNowDate() {
    let date = new Date();
    let year = date.getFullYear()
    let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return year + '-' + month + '-' + day
}

// 获取当前全部日期
function getNowTime() {
    let dateTime
    let yy = new Date().getFullYear()
    let mm = new Date().getMonth() + 1
    let dd = new Date().getDate()
    let hh = new Date().getHours()
    let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
    let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
    dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    return dateTime
}

// 获取当前年月
function getNowYearMonth() {
    let date = new Date();
    let year = date.getFullYear()
    let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    return year + '-' + month
}

//获取某一天的日期
function getDate(num, str) {
    var today = new Date();
    var nowTime = today.getTime();
    var ms = 24 * 3600 * 1000 * num;
    today.setTime(parseInt(nowTime + ms));
    var oYear = today.getFullYear();
    var oMoth = (today.getMonth() + 1).toString();
    if (oMoth.length <= 1) oMoth = '0' + oMoth;
    var oDay = today.getDate().toString();
    if (oDay.length <= 1) oDay = '0' + oDay;
    return oYear + str + oMoth + str + oDay;
}

// 获取往前推m个月的日期
function getPreMonthDay(m) {
    var preYear = 0;
    var resultDate, year, month, day;
    var currDate = new Date();
    year = currDate.getFullYear();
    month = currDate.getMonth() + 1;
    day = currDate.getDate();
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) - m;
    if (month2 <= 0) {
        preYear = parseInt(m / 12);
        year2 = parseInt(year2 - preYear);
        month2 = 12 - (Math.abs(month2) % 12);
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    if (day2 < 10) {
        day2 = '0' + day2;
    }
    resultDate = year2 + '-' + month2 + '-' + day2;
    return resultDate;
}

//深拷贝对象
function deepClone(obj) {
    let _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
    return objClone
}

//数组是否包含id的对象
function arrayContainId(array, id, prop) {
    for (var i = 0; i < array.length; i++) {
        let item = array[i]
        if (item[prop] === id) {
            return true
        }
    }
    return false
}

//秒数转时分秒
//type(0): 01:02:03
//type(1): 01:02′03″
//type(2): 01时02分03秒
function formatSeconds(value, type) {
    var secondTime = parseInt(value); // 秒
    var minuteTime = 0; // 分
    var hourTime = 0; // 小时
    if (secondTime >= 60) { //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime >= 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var secondStr = '';
    var minuteStr = '';
    var hourStr = '';
    if (secondTime < 10) {
        secondStr = '0' + secondTime
    } else {
        secondStr = secondTime
    }
    if (minuteTime < 10) {
        minuteStr = '0' + minuteTime
    } else {
        minuteStr = minuteTime
    }
    if (hourTime < 10) {
        hourStr = '0' + hourTime
    } else {
        hourStr = hourTime
    }
    //type(0): 01:02:03
    //type(1): 01:02′03″
    //type(2): 01时02分03秒
    var result = "";
    if (type === 0) {
        if (hourTime > 0) {
            result += hourStr + ':' + minuteStr + ':' + secondStr
        } else {
            result += minuteStr + ':' + secondStr
        }
    } else if (type === 1) {
        if (hourTime > 0) {
            result += hourStr + ':' + minuteStr + '′' + secondStr + '″'
        } else {
            result += minuteStr + '′' + secondStr + '″'
        }
    } else if (type === 2) {
        if (hourTime > 0) {
            result += hourStr + '时' + minuteStr + '分' + secondStr + '秒'
        } else {
            result += minuteStr + '分' + secondStr + '秒'
        }
    }
    return result;
}

/**
 * 四舍五入
 * @param num 小数
 * @param s 保留位数
 * @returns {string}
 */
function toFixed(num, s) {
    let times = Math.pow(10, s)
    let des = num * times + 0.5
    des = parseInt(des, 10) / times
    return des + ''
}

/**
 * @param {Object} 要转化的对象
 */
function getParamsString(obj) {
    let paramStr = ''
    for (let key of Object.keys(obj)) {
        let name = obj[key];
        if (paramStr === '') {
            paramStr += key + '=' + name
        } else {
            paramStr += '&' + key + '=' + name
        }
    }
    return paramStr
}

// 将el-icon的组件名称AbbbCddd转化为i-abbb-cddd形式
// 此前用switch做组件名时因关键字重复报错，所以格式统一加了前缀
// 例：Switch转换为i-switch，ArrowDownBold转换为i-arrow-down-bold
export function transElIconName(iconName) {
    return iconName.replace(/[A-Z]/g, (match) => match.toLowerCase())
}

/**
 * 检查输入框
 * @param {Object} value  值
 * @param {Object} number 小数位数
 */
function checkInput(value, number) {
    if (value === null) {
        value = ''
    } else {
        value += ''
    }
    if (number === 0) {
        if (value.indexOf('.') !== -1) {
            value = value.substr(0, value.indexOf('.'))
        }
        if (value.indexOf('。') !== -1) {
            value = value.substr(0, value.indexOf('。'))
        }
        return (value.match(/^\d*(\.?\d{0,0})/g)[0]) || null
    } else if (number === 1) {
        return (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    } else if (number === 2) {
        return (value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
    } else if (number === 3) {
        return (value.match(/^\d*(\.?\d{0,3})/g)[0]) || null
    } else if (number === 4) {
        return (value.match(/^\d*(\.?\d{0,4})/g)[0]) || null
    }
}

/**
 * 数组分成多个数组
 * @param array        要分割的数组
 * @param subGroupLength  每个数组的长度
 * @returns {*[]}
 */
function arraySplit(array, subGroupLength) {
    if (!array) {
        return []
    }
    let index = 0;
    let newArray = [];
    while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
}

//计算对象数组中某个属性合计
function countTotal(arr, keyName) {
    let $total = 0;
    $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
        return currentValue[keyName] ? (total + currentValue[keyName]) : total;
    }, 0);
    return toFixed($total, 3);
}

//判断是否为空
function isEmpty(value) {
    return value === null || value === undefined || value.toString().trim() === '';
}

//判断输入框是否输入，不能为0
function isEmptyOrZero(value) {
    return value === null || value === undefined || value.toString().trim() === '' || Number(value) === 0;
}

//多个数组排列组合
function multiplyData(newArrData) {
    let len = newArrData.length;
    // 当数组长度大于等于2时
    if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
            for (var j = 0; j < len2; j++) {
                if (items.length < index + 1) {
                    items.push([])
                }
                if (newArrData[0][i] instanceof Array) {
                    items[index] = items[index].concat(newArrData[0][i].concat(newArrData[1][j]));
                } else {
                    items[index] = items[index].concat([newArrData[0][i], newArrData[1][j]]);
                }
                index++;
            }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
            newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return multiplyData(newArr);
    } else {
        let arr = []
        for (let item of newArrData[0]) {
            if (Array.isArray(item)) {
                arr.push(item)
            } else {
                arr.push([item])
            }
        }
        return arr;
    }
}


export default {
    title,
    // 验证手机号
    is_phone,
    // 邮箱验证
    is_email,
    // 非空验证
    is_text,
    // 密码验证
    is_password,
    // 身份证验证
    is_card,
    // 存储信息
    set_data,
    // 读取信息
    get_data,
    // 超出省略
    slh,
    //是否是手机
    isMobile,
    //获取当前日期
    getNowDate,
    //获取当前时间
    getNowTime,
    //获取当前年月
    getNowYearMonth,
    //获取某一天的日期
    getDate,
    //获取往前推几个月的日期
    getPreMonthDay,
    //深拷贝对象
    deepClone,
    //数组包含某个id
    arrayContainId,
    //格式化秒数
    formatSeconds,
    //四舍五入保留小数
    toFixed,
    //转化对象为请求参数
    getParamsString,
    //检查输入框
    checkInput,
    //数组分成多个数组
    arraySplit,
    //计算对象数组中某个属性合计
    countTotal,
    //判空
    isEmpty,
    //判空和0
    isEmptyOrZero,
    multiplyData
}
